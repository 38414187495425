import Layout from '../layouts/layout'
import React from 'react'

class PageContent extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <Layout location={this.props.location}>
        <div className="container py-5 coral mb-5">
            <div class="row">
                <div class="offset-md-3 col-md-6">
                    <div className="pb-3">Subscribing to emails from Simple Summers keeps you "in the know."  Emails may include: When new camps are added, when camps open for registration, tips for busy parents and caregivers, product and feature updates, and more!</div>

                    <form action="https://simplesummers.us19.list-manage.com/subscribe/post?u=a8cc0272c9c5b66280253853a&amp;id=226373dae2" method="post" target="_blank">
                        <div class="row">
                            <div class="form-group col-sm">
                                <label for="mce-EMAIL" />
                                <input type="email" class="form-control" autocomplete="new-password" required="required" name="EMAIL" id="mce-EMAIL" placeholder="Email Address" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm">
                                <label for="mce-FNAME" />
                                <input type="text" class="form-control" autocomplete="new-password" name="FNAME" id="mce-FNAME" placeholder="First Name" />
                            </div>
                            <div class="form-group col-sm">
                                <label for="mce-LNAME" />
                                <input type="text" class="form-control" autocomplete="new-password" name="LNAME" id="mce-LNAME" placeholder="Last Name" />
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="form-group col-sm text-center m-0">
                                <button type="submit" class="btn btn-warning border">Subscribe</button>
                            </div>
                        </div>
                        <div class="d-none" aria-hidden="true">
                            <input type="text" name="b_a8cc0272c9c5b66280253853a_226373dae2" tabindex="-1" value="" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </Layout>
    )
  }
}

export default PageContent
